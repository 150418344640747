<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="balance"
            rules="required"
          >
            <b-form-group
              label="balance"
              label-for="balance"
            >
              <b-form-input
                id="balance"
                v-model="proceedForm.balance"
                :state="getValidationState(validationContext)"
                type="number"
                trim
                placeholder="balance"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="currency"
          >

            <b-form-group
              label="Currency "
              label-for="Currency"
            >
              <b-form-select
                id="currency"
                v-model="proceedForm.currency"
                :options="optionCurrency"
                trim
                placeholder="Currency"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addOffer()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addOffer()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {},
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      optionCurrency: [],
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const proceedForm = ref({
      balance: '',
      currency: '',
    })
    return {
      getValidationState,
      proceedForm,
    }
  },
  mounted() {
    this.showDelegator()
    this.getCurrency()
  },

  methods: {
    getCurrency() {
      if (this.$route.params.id) {
        axios.get('https://gulftic-system.fci.group/api/currency/available').then(res => {
          this.optionCurrency = decryptData(res.data.payload).data?.currencies
        })
      } else {
        return false
      }
      return true
    },
    showDelegator() {
      if (this.$route.params.id) {
        axios.get(`/delegator/signed/${this.$route.params.id}`).then(res => {
          // console.log(decryptData(res.data.payload))
          this.proceedForm.balance = decryptData(res.data.payload).data?.delegator?.wallet_balance
          this.proceedForm.currency = decryptData(res.data.payload).data?.delegator?.wallet_currency
        })
      } else {
        return false
      }
      return true
    },
    addOffer() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.proceedForm) {
          formData.append(key, this.proceedForm[key])
        }

        axios
          .post(`delegator/${this.$route.params.id}/balance`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'delegator' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
